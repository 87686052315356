@import '../../global/styles/lib';

.home-slider {
    @include full-bleed;
    padding-bottom: $block-padding;
    opacity: 1;
    transition: opacity $hover-transition;
    
    &.no-vis {
        opacity: 0;
        visibility: hidden;
        max-height: 0;
    } // .no-vis

    .slide {
        position: relative;
        width: 50rem;
        height: 28.125rem;
        max-width: 85vw;
        max-height: calc((85vw) / (16/9)); // maintain aspect ratio

        .container {          
            img {
                @include absolute-full;
                object-fit: cover;
                object-position: top;
            } // img
        } // .container
    } // .slide

    .slick-slide {
        margin: 0 0.25rem;
        position: relative;

        &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;  
            opacity: 1;
            background-color: rgba($masc-blue-medium, 1);
            transition: opacity 0.5s ease-in-out;
        }

        &.slick-current {
            & > img {
                filter: grayscale(0);
            }

            &::after {
                opacity: 0;
            }
        } // .slick-current

        // Fixes snapping problem when slider loops
        &[aria-hidden="true"]:not([tabindex="-1"]) + .slick-cloned[aria-hidden="true"]  {
            &::after {
                opacity: 0;
            }

            img {
                filter: grayscale(0);
            }
        }

    } // .slick-slide

    .slick-dots {
        .slick-active {
            button::before {
                opacity: 1;
                color: $masc-burnt-orange-medium;
            } // button::before
        } // .slick-active
    } // .slick-dots

} // .home-slider