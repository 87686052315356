/**
 * Imports libraries of settings, mixins, functions, etc. that should be available
 * to all stylesheets. MUST NOT OUTPUT STYLES.
 *
 * @author    <corey@chatmandesign.com>
 * @since     3.0.0
 */
/**
 * Breakpoint range variables for the lock mixin.
 * Convert the breakpoints map into range variables like we had in ZF5. The
 * magic number at the end is the difference between the breakpoint we define in
 * $breakpoints and the actual max-width value spit out by the breakpoints()
 * mixin when we give it an "only" argument (to avoid overlap. See the ZF5 media
 *  query range variables for reference.)
 *
 * $small-range is a special snowflake. The whole purpose of the lock mixin is
 * to let you set a property to a fixed value up to a certain breakpoint, then
 * have that value increase in a linear fashion until you hit another
 * breakpoint, and then keep the value fixed again for the rest of the way. The
 * way the breakpoints are defined, the small range would be 0px - 640px. But
 * we're not planning on supporting devices smaller than 320px, so it's far more
 * useful for the small range to be 320px to 640px. That way, the $min-value you
 * pass to the lock mixin is anchored to the smallest device width we support,
 * and the $max-value is anchored to the breakpoint between small and medium
 * ranges.
 *
 * @author   <dustin@chatmandesign.com>
 */
/**
 * Sass Mixins
 *
 * @package   seedyjoints
 * @since     1.0
 */
/**
 * Extend element's background to left and right edges of viewport.
 *
 * @since    3.0.0
 * @author   <corey@chatmandesign.com>
 *
 * @param string $position  Any valid CSS position value, or NULL/FALSE to not set
 *                          position; but note the element must create a positioning
 *                          context for the bleed effect to work. Default: relative.
 * @param string $direction Direction the bleed will extend in -- left, right, both.
 *                          Deafult: both.
 */
/**
 * Lock (Fluid Type Mixin -- CD Remix)
 * Created 10/11/16 @ 02:19:30 PM
 *
 * Original from here:
 * http://madebymike.com.au/writing/precise-control-responsive-typography/
 *
 * This is a modified version of the above mixin that takes a single SCSS list
 * instead of two variables for min and max VW. This makes it easier to use with
 * the existing Foundation range variables in _settings.scss. You can of course
 * still pass a plain list e.g. "(20rem, 40rem)" as the argument for the range
 * parameter if you need a custom range.
 *
 * @param      $properties A space separated list of CSS properties
 * @param      $vw-range A SCSS list defining a viewport width range like
 *             "(20em, 40em)".
 * @param      $min-value A minimum property value in px/em/rem
 * @param      $max-value A maximum property value in px/em/rem
 * @author     Chatman Design / Dustin Paluch <dustin@chatmandesign.com>
 */
/**
 * List items inline, separated by pipes by default.
 *
 * @since    3.0.0
 * @author   <corey@chatmandesign.com>
 */
/**
 * Transition property with vendor prefixes (DEPRECATED)
 *
 * DEPRECATED now that we use autoprefixer. Just use the standard CSS property.
 *
 * I totally rewrote this to be cleaner and easier to use. You'll need to be using
 * Sass 3.2+ for these to work. Thanks to @anthonyshort for the inspiration on these.
 *
 * USAGE: @include transition(<property> <time> <easing>);
 */
/**
 * CSS3 Gradients with vendor prefixes
 *
 * Be careful with these since they can really slow down your CSS. Don't overdo it.
 */
/* @include css-gradient(#dfdfdf,#f8f8f8); */
/**
 * Foundation for Sites
 * Version 6.6.3
 * https://get.foundation
 * Licensed under MIT Open Source
 */
.home-slider {
  width: 100vw;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  position: relative;
  max-width: 160rem;
  padding-bottom: 4rem;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}
.home-slider.no-vis {
  opacity: 0;
  visibility: hidden;
  max-height: 0;
}
.home-slider .slide {
  position: relative;
  width: 50rem;
  height: 28.125rem;
  max-width: 85vw;
  max-height: 47.8125vw;
}
.home-slider .slide .container img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
}
.home-slider .slick-slide {
  margin: 0 0.25rem;
  position: relative;
}
.home-slider .slick-slide::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
  background-color: #2b8ac7;
  transition: opacity 0.5s ease-in-out;
}
.home-slider .slick-slide.slick-current > img {
  filter: grayscale(0);
}
.home-slider .slick-slide.slick-current::after {
  opacity: 0;
}
.home-slider .slick-slide[aria-hidden=true]:not([tabindex="-1"]) + .slick-cloned[aria-hidden=true]::after {
  opacity: 0;
}
.home-slider .slick-slide[aria-hidden=true]:not([tabindex="-1"]) + .slick-cloned[aria-hidden=true] img {
  filter: grayscale(0);
}
.home-slider .slick-dots .slick-active button::before {
  opacity: 1;
  color: #dd8d48;
}